import { createTransitions } from '../../domain/transition';

export default createTransitions([
  ['41-2031', 'Retail salespersons', 0.0427, 14.1, 29360],
  ['43-4051', 'Customer service representatives', 0.0421, 17.9, 37300],
  ['11-9051', 'Food service managers', 0.0407, 28.76, 59820],
  ['35-3011', 'Bartenders', 0.039, 13.46, 28000],
  [
    '43-6014',
    'Secretaries and administrative assistants, except legal, medical, and executive',
    0.0391,
    18.84,
    39180,
  ],
  ['43-9061', 'Office clerks, general', 0.034, 17.48, 36360],
  ['41-2011', 'Cashiers', 0.0312, 11.72, 24370],
  ['11-9000', 'Other Management Occupations', 0.0303, 49.31, 102560],
  [
    '35-1012',
    'First-line supervisors of food preparation and serving workers',
    0.0231,
    17.77,
    36960,
  ],
  [
    '41-1011',
    'First-line supervisors of retail sales workers',
    0.0199,
    22.03,
    45830,
  ],
  ['43-4171', 'Receptionists and information clerks', 0.017, 15.02, 31250],
  ['53-3032', 'Heavy and tractor-trailer drivers', 0.0158, 22.52, 46850],
  ['43-4081', 'Hotel, motel, and resort desk clerks', 0.0148, 12.47, 25950],
]);

export const longData = createTransitions([
  [
    '11-9041',
    'Architectural and Engineering Managers',
    0.006502,
    73.52,
    152930,
  ],
  ['23-1011', 'Lawyers', 0.0059715, 69.86, 145300],
  ['27-3043', 'Writers and Authors', 0.0058946, 35.51, 73860],
  ['11-9199', 'Managers, All Other', 0.091688, 55.57, 115590],
  ['11-2022', 'Sales Managers', 0.0828744, 68.12, 141690],
  ['11-1021', 'General and Operations Managers', 0.0795529, 59.15, 123030],
  [
    '41-4012',
    'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products',
    0.0550769,
    34.19,
    71110,
  ],
  [
    '13-1161',
    'Market Research Analysts and Marketing Specialists',
    0.0533706,
    34.41,
    71570,
  ],
  ['13-1111', 'Management Analysts', 0.0449291, 45.94, 95560],
  ['11-1011', 'Chief Executives', 0.0356511, 93.2, 193850],
  ['15-1199', 'Computer Occupations, All Other', 0.0264826, 44.88, 93350],
  [
    '53-3032',
    'Heavy and Tractor-Trailer Truck Drivers',
    0.0227579,
    22.52,
    46850,
  ],
  [
    '11-2031',
    'Public Relations and Fundraising Managers',
    0.0196887,
    63.26,
    131570,
  ],
  ['11-9111', 'Medical and Health Services Managers', 0.0194742, 55.37, 115160],
  [
    '41-1011',
    'First-Line Supervisors of Retail Sales Workers',
    0.0172599,
    22.03,
    45830,
  ],
  ['15-2031', 'Operations Research Analysts', 0.012214, 43.56, 90600],
  ['27-3031', 'Public Relations Specialists', 0.0119981, 33.75, 70190],
  [
    '11-9033',
    'Education Administrators, Postsecondary',
    0.0108165,
    54.04,
    112400,
  ],
  [
    '43-1011',
    'First-Line Supervisors of Office and Administrative Support Workers',
    0.010252,
    28.91,
    60130,
  ],
  ['15-1132', 'Software Developers, Applications', 0.0092665, 51.96, 108080],
  ['11-3031', 'Financial Managers', 0.0089749, 70.93, 147530],
  ['41-2031', 'Retail Salespersons', 0.0086012, 14.12, 29360],
  ['13-1071', 'Human Resources Specialists', 0.0082867, 32.58, 67760],
  ['43-4051', 'Customer Service Representatives', 0.0082068, 17.94, 37320],
  ['27-3041', 'Editors', 0.0072176, 34.57, 71910],
  [
    '41-3099',
    'Sales Representatives, Services, All Other',
    0.0071859,
    31.18,
    64860,
  ],
  ['11-3051', 'Industrial Production Managers', 0.0070336, 55.34, 115110],
  [
    '43-6014',
    'Secretaries and Administrative Assistants, Except Legal, Medical, and Executive',
    0.0069207,
    18.84,
    39180,
  ],
  [
    '13-1121',
    'Meeting, Convention, and Event Planners',
    0.0065356,
    26.39,
    54880,
  ],
  [
    '43-6011',
    'Executive Secretaries and Executive Administrative Assistants',
    0.0057956,
    30.25,
    62920,
  ],
  ['11-9051', 'Food Service Managers', 0.0057434, 28.76, 59820],
  ['27-1011', 'Art Directors', 0.0057088, 52.69, 10],
]);
